import React from "react"
import classNames from "classnames";

import * as styles from "./styles.module.scss";
import * as box from "styles/box.module.scss";
import * as R from "ramda";

const AiringItem = ({airing, index}) => {
  // for scores under 10, display 1 decimal place
  const scoreNum = Number(airing.score);
  const score = (scoreNum < 10 ? scoreNum.toFixed(1) : scoreNum.toFixed(0)).toLocaleString();

  const wrappedOnClick = (e) => {
    if (R.isNil(airing.creativeId) || R.isEmpty(airing.creativeId)) {
      return;
    }

    let gotoLink = `https://player.edo.com/public/watch/${airing.creativeId}`;

    if (gotoLink) {
      window.open(gotoLink, "_blank");
    }
  };

  return (
    <div className={classNames(styles.backgroundContainer, !(R.isNil(airing.creativeId) || R.isEmpty(airing.creativeId)) ? styles.linkHover : styles.noLink)} onClick={wrappedOnClick}>
      <div className={classNames("flex-row")}>
        <div className={classNames(styles.numberContainer, styles.outerText)}>{index}</div>

        <div className={classNames(styles.item)}>

          <div className={classNames("flex-row-between")}>
            <div className={classNames(styles.titleContainer)}>
              <div className={classNames("uk-margin-small-left")}><b>{airing.name}</b></div>
            </div>

            <div className={classNames(styles.infoContainer)}>
              <div className={classNames(styles.descriptionContainer)}>
                <div className={styles.descriptionText}>
                  <i>{airing.description}</i>
                  <div className={classNames(box.marginTop10, styles.metadataText, "flex-row")}>
                    <div className={classNames(styles.duration)}><b>{airing.duration} Secs</b></div>
                    <div className={classNames(styles.theDot)}> • </div>
                    <div><i><b>{airing.time}</b></i></div>
                    { airing.quarter ? (
                        <>
                          <div className={classNames(styles.theDot)}> • </div>
                          <div className={classNames(styles.quarter)}><i><b>{airing.quarter}</b></i></div>
                        </>
                      ) : null }
                  </div>
                </div>
              </div>
            </div>

            <div className={classNames(styles.scoreContainer)}>
              <div className={classNames(styles.outerText)}>{score}</div>
            </div>
          </div>

          {/*mobile version*/}
          <div className={classNames(styles.infoMobileContainer, styles.item)}>
            <div className={classNames(styles.descriptionContainer, "uk-flex")}>
              <div className={styles.descriptionText}>
                <i>{airing.description}</i>
                <div className={classNames(box.marginTop10, styles.metadataText, "flex-row")}>
                  {/*<b>{airing.duration} Secs</b> &nbsp; <i><b>{airing.quarter} &nbsp;{airing.time}</b></i>*/}
                  <div className={classNames(styles.duration)}><b>{airing.duration} Secs</b></div>
                  <div className={classNames(styles.theDot)}> • </div>
                  <div className={classNames(styles.clockTime)}><i><b>{airing.time}</b></i></div>
                  { airing.quarter ? (
                      <>
                        <div className={classNames(styles.theDot)}> • </div>
                        <div className={classNames(styles.quarter)}><i><b>{airing.quarter}</b></i></div>
                      </>
                  ) : null }
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  );
};

export default AiringItem;
