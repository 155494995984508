// extracted by mini-css-extract-plugin
export var backgroundContainer = "styles-module--background-container--EK87q";
export var descriptionContainer = "styles-module--description-container--zhoWW";
export var descriptionText = "styles-module--description-text--NWYRh";
export var infoContainer = "styles-module--info-container--Bquxj";
export var infoMobileContainer = "styles-module--info-mobile-container--UwtnF";
export var innerText = "styles-module--inner-text--B5-mK";
export var item = "styles-module--item--KFp6z";
export var linkHover = "styles-module--link-hover--edXgC";
export var metadataText = "styles-module--metadata-text--B4Fvo";
export var noLink = "styles-module--no-link--s69yU";
export var numberContainer = "styles-module--number-container--1pjAd";
export var outerText = "styles-module--outer-text--o6J6O";
export var quarter = "styles-module--quarter--y9dMK";
export var scoreContainer = "styles-module--score-container--e0ggF";
export var theDot = "styles-module--the-dot--FrGKh";
export var timeContainer = "styles-module--time-container--p-6Kg";
export var titleContainer = "styles-module--title-container--446lY";