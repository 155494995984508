import React, { useState } from "react"
import classNames from "classnames";
import * as styles from "./styles.module.scss";
import * as R from "ramda";

const SuperbowlTile = (props: any) => {

    const wrappedOnClick = (e) => {
      if (R.isNil(props.creativeId) || R.isEmpty(props.creativeId)) {
        return;
      }

      let gotoLink = `https://player.edo.com/public/watch/${props.creativeId}`;

      if (gotoLink) {
        window.open(gotoLink, "_blank");
      }
    };

    return (
      <div className={classNames(styles.tileContainer)} onClick={wrappedOnClick}>
        <img
          className={props.hover ? styles.hoverImage : ""}
          src={props.url}
          alt={props.creativeId}
        />
      </div>
    )
}

export default SuperbowlTile;
