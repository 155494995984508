import React from "react"
import classNames from "classnames";
import * as R from "ramda";
import TopAdsEmailForm from "components/top_ads_email_form";
import Button from "components/button";
import * as styles from "./styles.module.scss";

class SignUpButton extends React.Component {
  constructor(props) {
    super(props);
    this.originalStyle = props.style;
    this.originalButtonStyle = props.buttonStyle;
    this.href = props.href;
    this.state = {
      title: props.title,
      subTitle: props.subTitle,
      subTitleStyle: props.subTitleStyle,
      style: props.style,
      buttonTitle: props.buttonTitle,
      buttonStyle: props.buttonStyle,
      scrollStyle: props.scrollStyle,
      scrollButtonStyle: props.scrollButtonStyle,
      scrollStyleHeightThreshold: parseInt(props.scrollStyleHeightThreshold || 300, 10),
    };

    this.signUpRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, true);
  }

  componentWillUnmount() {
    this.signUpRef.current = null;
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll=() => {
    // if (!this.signUpRef.current) {
    //   return;
    // }

    // let offsetTop = R.view(R.lensPath(["current", "offsetTop"]), this.signUpRef);

    // if (!!offsetTop && (offsetTop - window.pageYOffset) < this.state.scrollStyleHeightThreshold) {
    //   this.setState({style: this.state.scrollStyle});
    //   this.setState({buttonStyle: this.state.scrollButtonStyle});
    // }
    // else {
    //   this.setState({style: this.originalStyle});
    //   this.setState({buttonStyle: this.originalButtonStyle});
    // }
  };

  render() {
    return (
      <div className={classNames("flex-col", styles.backgroundContainer)} style={this.state.style}>
        <div className={classNames("uk-flex uk-flex-row uk-text-center ")}>
          <div ref={this.signUpRef} className={classNames("uk-flex uk-text-center uk-column-1-2", styles.text)}>
            <div className={classNames(styles.items)}>
              <div className={classNames("", styles.title)}>
                {this.state.title || "Want to see how your industry stacked up in the Big Game?"}
              </div>
              {/* <div className={classNames("", styles.subTitle)} style={this.state.subTitleStyle}>
                {this.state.subTitle}
              </div> */}
            </div>
          </div>
        </div>
        <div className={classNames(styles.buttonContainer)}>
          <Button className={styles.requestReport} href={this.href || "https://landing.edo.com/en-us/super-bowl-2023-industry-share-of-search"}>
            Request Report
          </Button>
          {/* <TopAdsEmailForm source="superbowl" superBowl/> */}
        </div>
      </div>
    )
  }
}

export default SignUpButton;
